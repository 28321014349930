var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex border border-white relative",class:{
    'h-7 text-xl': _vm.small,
    'h-12 text-2xl' : !_vm.small
  }},[(_vm.disabled)?_c('div',{staticClass:"absolute h-full w-full l-0 t-0 z-10 bg-black opacity-25"}):_vm._e(),_vm._l((_vm.options),function(option){return _c('div',{key:option.value,staticClass:"flex items-center h-full border-r border-white last:border-0 cursor",class:{
      'bg-white text-purple': option.value === _vm.value,
      'px-2': _vm.small,
      'px-6': !_vm.small
    },on:{"click":function($event){return _vm.$emit('input', option.value)}}},[_c('span',[_vm._v(_vm._s(option.label))])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }