var render = function render(){var _vm=this,_c=_vm._self._c;return _c(`h${_vm.level}`,{tag:"component",class:{
    'text-5xl': _vm.styleLevel === '1',
    'text-4xl': _vm.styleLevel === '2',
    'text-3xl': _vm.styleLevel === '3',
    'text-2xl': _vm.styleLevel === '4',
    'text-xl': _vm.styleLevel === '5',
    'text-l': _vm.styleLevel === '6'
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }