var render = function render(){var _vm=this,_c=_vm._self._c;return _c('bordered',[_c('div',{staticClass:"h-24 flex items-centerrelative border-4 bg-dark hover:bg-dark-highlight pl-10 cursor",class:{
      'border-gold': `${_vm.rank}` === '1',
      'border-silver': `${_vm.rank}` === '2',
      'border-bronze': `${_vm.rank}` === '3'
    }},[_c('div',{staticClass:"flex-1 py-4"},[_c('div',{staticClass:"absolute -left-1 top-4 h-6 w-7 text-center text-lg leading-6 bg-purple"},[_vm._v(" #"+_vm._s(_vm.rank)+" ")]),_c('div',{staticClass:"text-2xl leading-6 mb-2"},[_vm._v(" "+_vm._s(_vm.address)+" ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex items-center mr-6"},[_c('img',{staticClass:"h-4 w-4 mr-1",attrs:{"src":require("../../../assets/fud.png"),"alt":"Fud token"}}),_c('span',{staticClass:"text-2xl leading-6 -mt-0.5"},[_vm._v(_vm._s(_vm.totalFud))])]),_c('div',{staticClass:"flex items-center mr-6"},[_c('img',{staticClass:"h-4 w-4 mr-1",attrs:{"src":require("../../../assets/fomo.png"),"alt":"Fomo token"}}),_c('span',{staticClass:"text-2xl leading-6 -mt-0.5"},[_vm._v(_vm._s(_vm.totalFomo))])]),_c('div',{staticClass:"flex items-center mr-6"},[_c('img',{staticClass:"h-4 w-4 mr-1",attrs:{"src":require("../../../assets/alpha.png"),"alt":"Alpha token"}}),_c('span',{staticClass:"text-2xl leading-6 -mt-0.5"},[_vm._v(_vm._s(_vm.totalAlpha))])]),_c('div',{staticClass:"flex items-center mr-6"},[_c('img',{staticClass:"h-4 w-4 mr-1",attrs:{"src":require("../../../assets/kek.png"),"alt":"Kek token"}}),_c('span',{staticClass:"text-2xl leading-6 -mt-0.5"},[_vm._v(_vm._s(_vm.totalKek))])])])]),_c('div',{staticClass:"flex items-center text-3xl w-36 h-full"},[_vm._v(" "+_vm._s(_vm.fudStandardSpent)+" ")]),(_vm.ghstPrize)?_c('div',{staticClass:"w-32 h-full flex items-center",class:{
        'bg-gold': `${_vm.rank}` === '1',
        'bg-silver': `${_vm.rank}` === '2',
        'bg-bronze': `${_vm.rank}` === '3'
      }},[_c('div',{staticClass:"w-full flex items-center pl-6 text-3xl text-dark"},[_c('img',{staticClass:"w-4 h-4 mt-1 mr-1.5",attrs:{"src":require("../../../assets/ghst.png"),"alt":"GHST logo"}}),_c('div',[_vm._v(_vm._s(Number(_vm.ghstPrize).toLocaleString()))])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }